import { StringLike } from '@firebase/util';

export class Ledger {
  id!: string;
  code!: string;
  plan_id!: string;
  plan_name!: string;
  customer_name!: string;
  phone!: string;
  plan_type!: string;
  cost!: number;
  duration!: number;
  created_on!: string;
  weight_or_amount!: string;
  status!: string;
  installments!: string;
}
//models declerations
export class Emi {
  plan_id!: string;
  code!: string;
  installments!: string;
  plan_name!: string;
  plan_date!: string;
  amount!: number;
  rate!: number;
  weight!: number;
  payment_mode!: string;
  payment_id!: string;
  settlement_id!: string;
  settlement_date!: string;
  accumulated_weight!: string;
  is_online!: boolean;

  formatForPrint(element:any) {
    const copy_emi: any = {}; // Initialize copy_emi as an empty object

    copy_emi.rc = element.is_online ? "--" : this.payment_id;
    copy_emi.ins = element.installments;
    copy_emi.date = element.plan_date;
    copy_emi.code = element.code;
    copy_emi.amount = element.amount; // Corrected typo here
    copy_emi.wt = element.weight;
    copy_emi.rt = element.rate;
    copy_emi.tw = parseFloat(element.accumulated_weight.match(/[\d.]+/)?.[0] || '0').toFixed(3);

    return copy_emi; // Return the formatted object
  }
}


export class EMI_DATA {
  amount!: string;
  code!: string;
  duration!: number;
  installments!: string;
  start_date!: string;
  status!: string;
  total_weight!: string;
  type!: string;
  due_installments!: string;
}

export class ClosePlan {
  id!: string;
  closing_date!: string;
  invoice!: string;
  phone!:string;
  otp!:string;
  constructor(id: string, closing_date: string, invoice: string,phone:string,otp:string) {
    this.id = id;
    this.closing_date = closing_date;
    this.invoice = invoice;
    this.phone = phone;
    this.otp = otp;
  }
}



export class SubscriptionNote{
  id!:number;
  note!:string;
  constructor(id:number,note:string){
    this.id=id;
    this.note=note;
  }
}
