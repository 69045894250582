import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClosePlan, Emi, Ledger, SubscriptionNote } from 'src/app/models/ledger';
import { ResponseMessage } from 'src/app/models/message';
import { Rest } from 'src/app/models/rest_model';
import { environment } from 'src/environments/environment';
import { PlanName } from 'src/app/models/planname';
import { InstallmentPrint } from 'src/app/models/installments';

@Injectable({
  providedIn: 'root'
})
export class LedgerServiceService {
  subscription_url = environment.ip + "shop/ledger/";
  plan_url = environment.ip + "shop/savings-plan/get_plans/";
  createSubUrl = environment.ip + "shop/savings-plan/create_subscription/";
  send_otp_url = environment.ip + "shop/users/send_otp/";
  otpValidate = environment.ip + "shop/users/validate_otp/"
  re_send_otp_url = environment.ip + "shop/users/re_send_otp/";
  download_url = environment.uploaderIp + "shop/ledger/";

  constructor(private http: HttpClient) { }

  sendOtpForClosePlan(mobile_number: any) {
    let fd = new FormData();
    fd.append("mobile", mobile_number);
    return this.http.post(`${environment.ip}shop/savings-plan/send_otp_for_close_plan/`, fd);
  }
  getLedgers(page: number, search: string, ordering: string, status: string) {
    return this.http.get<Rest<Ledger>>(this.subscription_url, { params: { "search": search, "page": page, "ordering": ordering, "status": status } });
  }
  getEmiData(id: string) {
    return this.http.get<Array<Emi>>(this.subscription_url + id + "/get_emis/");
  }

  getInstallmentData(id:number)
  {
    return this.http.get<InstallmentPrint>(this.subscription_url + id + "/get_installment_data/")
  }

  downloadLedger(fd: FormData) {
    return this.http.post(this.download_url + "download_ledger/", fd);
  }
  sendOtp(fd: FormData) {

    return this.http.post(this.send_otp_url, fd);

  }

  resendOtp(fd: FormData) {
    return this.http.post(this.re_send_otp_url, fd);

  }
  getPlans() {
    return this.http.get<Array<PlanName>>(this.plan_url);
  }

  dataForInstallment(fd: FormData) {
    return this.http.post<ResponseMessage>(this.createSubUrl, fd);
  }

  deleteEmiRow(id: any) {
    return this.http.post<ResponseMessage>(`${environment.ip}shop/savings-plan/${id}/deactivate_emi/`, {});
  }

  validateOtp(fd: FormData) {
    return this.http.post(this.otpValidate, fd);
  }

  closePlan(close_plan: ClosePlan) {
    return this.http.post(`${environment.ip}shop/savings-plan/closePlan/`, close_plan)
  }

  getClosePlan(id: number) {
    return this.http.get(`${environment.ip}shop/savings-plan/${id}/getClosedPlan/`);
  }

  saveNote(subscription_note:SubscriptionNote){
    return this.http.post(`${environment.ip}shop/savings-plan/${subscription_note.id}/addNote/`,{note:subscription_note.note});
  }

  getNote(id: number) {
    return this.http.get(`${environment.ip}shop/savings-plan/${id}/getNote/`);
  }

}