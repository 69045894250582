<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div *ngIf="table" class="head side">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <!-- <h3 (click)="back()" class="back_title">
          <mat-icon class="right_back">arrow_back_ios</mat-icon> Customer Ledger
        </h3> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <mat-spinner
          *ngIf="dataSource.loading$ | async"
          style="margin: 0 auto"
          mode="indeterminate"
          [diameter]="50"
        ></mat-spinner>
      </div>
    </div>
    <div class="row">
      <!-- <mat-form-field >
        <mat-label>Outline form field</mat-label>
        <input matInput placeholder="Placeholder">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field> -->
      <div class="col-md-5 mt-2">
        <div class="input-group" style="margin-left: 15px; height: 48px">
          <mat-icon
            class="searchIcon cursor"
            id="23"
            [ngStyle]="{ color: '#9A9AB0' }"
            >search</mat-icon
          >

          <input
            [(ngModel)]="search"
            [disabled]="search_field_disable"
            type="text"
            class="form-control search_box roundSquare text-indent cursor"
            id="23"
            style="border: 0; height: 48px"
            placeholder="Search Plan ID, Customer Name, Plan type"
            (keyup.enter)="searchOption(); txtInput.blur()"
            #txtInput
          />
          <mat-icon
            *ngIf="search"
            class="crossIcon"
            (click)="clearInput(); searchOption()"
            [ngStyle]="{ color: '#9A9AB0' }"
            id="23"
            >cancel</mat-icon
          >

          <button
            (click)="searchOption()"
            mat-raised-button
            id="23"
            class="searchButton cursor"
            style="
              background: #4070f4;
              color: #ffffff;
              height: 48px;
              box-shadow: none;
            "
          >
            Search
          </button>
        </div>
      </div>
      <div class="col-md-1 mt-4">
        <div ngbDropdown   #myDropdown="ngbDropdown"  style="cursor: pointer" class="dropdownledger" placement="bottom-right">
          <i id="dropdownBasic1" ngbDropdownToggle>
            <img
              src="assets/images/drop.png"
              style="height: 50px; margin-top: 5px; margin-left: 13px"
            />
          </i>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
              ngbDropdownItem
              (click)="fetchStatus($any($event.target).value); myDropdown.close()"
              value=""

            >
              All
            </button>
            <button
              ngbDropdownItem
              (click)="fetchStatus($any($event.target).value); myDropdown.close()"
              value="IP"
            >
              In Progress
            </button>
            <button
              ngbDropdownItem
              (click)="fetchStatus($any($event.target).value); myDropdown.close()"
              value="CP"
            >
              Completed
            </button>
            <button
              ngbDropdownItem
              (click)="fetchStatus($any($event.target).value); myDropdown.close()"
              value="CL"
            >
              Cancelled
            </button>
            <button
            ngbDropdownItem
            (click)="fetchStatus($any($event.target).value); myDropdown.close()"
            value="CD"
          >
            Billed
          </button>
          </div>
        </div>
       
      </div>

      <!-- <div class="col-md-2 mt-4">
        <a>
          <button
            mat-raised-button
            color="primary"
            class="mt-2 add_install_button router_cursor"
            [routerLink]="['/dashboard/add_installments']"
            id="4"
          >
            Add Installments
          </button>
        </a>
      </div> -->
      <div class="col-md-3 mt-3">
        <!-- <a>
          <button
            [routerLink]="['/dashboard/add_emi']"
            mat-raised-button
            color="primary"
            class="mt-2 start_saving router_cursor"
          >
            Add Installment 
          </button>
        </a> -->
      </div>

      <div class="col-md-3">

      <div class="btn-group">

      <div class="mt-4">
        <a>
          <button
            [routerLink]="['/dashboard/start_jewellery_plan']"
            mat-raised-button
            color="primary"
            class="mt-2 start_saving router_cursor"
          >
            Start Jewellery plan
          </button>
        </a>
      </div>


      <div class="mt-4 leftClass" style="cursor:pointer">
          <img   [routerLink]="['/dashboard/jewellery_plans']"
            src="assets/images/setup.png"
            style="margin-top: 8px"
          />
      </div>

      <div class="mt-4 leftClass" style="cursor:pointer">
        <img    
          (click)="routeToPendingInstallment()"
          src="assets/images/pending_installment.svg"
          style=" height: 45px; margin-top: 8px"
        />
    </div>
      <div class="mt-4 leftClass" style="cursor:pointer">
          <img    (click)="actionsCheck(24) ? download(download_file) : null"
            src="assets/images/ButtonDownload.svg"
            style=" height: 45px; margin-top: 8px"
          />
      </div>
 
      

    </div>
    </div>
  </div>

  <div class="container-fluid mt-4">

    <div class="row tablePadding">
      <div class="col-md-12 text-center">
        <div *ngIf="!dataSource">
          <div class="text-center pt-5 mt-5">
            <img
              src="assets/images/ratesempty.png"
              class="img-fluid gold mt-3"
            />

            <h1 class="mt-2">No Reports Found</h1>
          </div>
        </div>
        <div *ngIf="dataSource" class="mat-elevation table-responsive">
          <table mat-table [dataSource]="dataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="plan_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Id</th>
              <td mat-cell *matCellDef="let element"   (click)="getEmis(element, emi_modal, element.code)" class="highlight cursor_pointer">
                {{ element.code }}
              </td>
            </ng-container>

            <ng-container matColumnDef="plan_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Plan Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.plan_name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.customer_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
              <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="plan_type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Plan Type
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.plan_type }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cost">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Plan Amount
              </th>
              <td mat-cell *matCellDef="let element">{{ element.cost  }}</td>
            </ng-container>
            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Duration
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.duration }} {{element.plan_type=='Daily Amount Based' ?'Days':'Months'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="start_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Start Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.created_on }}
              </td>
            </ng-container>
            <ng-container matColumnDef="total_or_weight">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Amount/Weight
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.weight_or_amount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let element"
              style="cursor:pointer"
              (click)="element.status=='Billed'?getClosedPlanData(element,close_plan):''">
                <span [ngStyle]="{ color: getColor(element.status) }">
                {{
                  element.status == "In Progress" ? "Active" : element.status
                }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="installments">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="text-align: center"
              >
                Installments
              </th>
              <td
                style="text-align: center"

                mat-cell
                *matCellDef="let element"

              >
                {{ element.installments }}
              </td>
            </ng-container>

            <ng-container matColumnDef="kebab">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let element" style="padding: 0">
                <span [ngStyle]=""></span>
                <button
                  *ngIf="handleKebab(element.status)"
                  class="newbi"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                >
                  <span
                    id="boot-icon"
                    class="bi bi-three-dots-vertical mt-1"
                    style="
                      font-size: 25px;
                      -webkit-text-stroke-color: rgb(0, 0, 0);
                    "
                  ></span>
                </button>
                <mat-menu #menu="matMenu" class="matmenu">
                  <a
                    [routerLink]="['/dashboard/add_installments']"
                    style="text-decoration: none"
                    [queryParams]="{ planN: element.code ,
                    is_amount : element.plan_type == 'Daily Amount Based' ? 'true' :'false',
                    }"
                  >
                    <button mat-menu-item>
                      <mat-icon>add</mat-icon>
                      <span>Add Installment</span>
                    </button>
                  </a>
  
                    <a *ngIf="element.status!='Cancelled' && element.status!='Completed'"
                      [routerLink]=" actionsCheck(39)? ['/dashboard/cancel_plan']:[null]"
                      style="text-decoration: none"
                      [queryParams]="{
                        vr_no: element.id,
                        planN: element.code,
                        name: element.customer_name,
                        phone: element.phone,
                        amount: element.cost,
                        paid: element.installments,
                        weight: element.weight_or_amount
                      }"
                    >
                      <button mat-menu-item>
                        <mat-icon>clear</mat-icon>
                        <span>Cancel Plan</span>
                      </button>
                    </a>
  
                    <a
                    (click)="getEmis(element, emi_modal, element.code)"
                    style="text-decoration: none"
                  >
                    <button mat-menu-item>
                      <mat-icon>view_list</mat-icon>
                      <span>View Installment</span>
                    </button>
                  </a>
                  <a
                  (click)="openNotesModel(element,add_notes)"
                  style="text-decoration: none"
                >
                  <button mat-menu-item>
                    <mat-icon>note</mat-icon>
                    <span>Add Note</span>
                  </button>
                </a>

                  

                  <a
                  *ngIf="element.status=='Completed'"
                  (click)="getClosedPlanData(element,close_plan_otp)"
                  style="text-decoration: none"
                >
                  <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Close Plan</span>
                  </button>
                </a>
                  </mat-menu>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
  
            <mat-paginator
              style="background-color: #f5f6f8"
              [pageSizeOptions]="[popUpManager.page_size_number]"
              showFirstLastButtons
            ></mat-paginator>
            <!--Here pagesizeoption is assigned with number of row-->
          </div>
          <div *ngIf="data_exist==false">
            <div class="text-center ">
              <h4 style="color: #606060;" class="mt-2">It seems you don't have any customer plans. To enroll customers, click Start Jewellery plan.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <ng-template #emi_modal let-modal>
    <div class="myModal emi">
      <div class="modal-content p-2 px-3">
        <div class="modal-header">
          <h1 class="modal-title emi-modal-title">Installment Details</h1>
          <button
            type="button"
            style="outline: none"
            class="close btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div
              class="col-md-12 card"
              style="height: 101px; width: 98.5%; margin-left: 10px"
            >
              <div class="row mt-2">
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
  
                    height: 50px;
                  "
                >
                  <h4 class="emiCardTitle text-center">
                    Plan ID
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">{{ emi_Data.code }}</h3>
                      </span>
                    </span>
                  </h4>
                </div>
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
                    border-left: 1px solid #DFDFDF;
                    height: 50px;
                  "
                >
                  <h4 class="emiCardTitle text-center">
                    Type
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">{{ emi_Data.type }}</h3>
                      </span>
                    </span>
                  </h4>
                </div>
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
                    border-left: 1px solid #DFDFDF;
                    height: 50px;
                  "
                >
                  <h4 class="emiCardTitle text-center">
                    Amount
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">{{emi_Data.amount}}</h3>
                      </span>
                    </span>
                  </h4>
                </div>
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
                    border-left: 1px solid #DFDFDF;
                    height: 50px;
                  "
                >
                  <h4 class="emiCardTitle text-center">
                    Duration
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">{{ emi_Data.duration }}</h3>
                      </span>
                    </span>
                  </h4>
                </div>
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
                    border-left: 1px solid #DFDFDF;
                    height: 50px;
                  "
                >
                  <h4 class="emiCardTitle text-center">
                    Start Date
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">
                          {{ emi_Data.start_date }}
                        </h3>
                      </span>
                    </span>
                  </h4>
                </div>
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
                    border-left: 1px solid #DFDFDF;
                    height: 50px;
                  "
                >
                  <h4 class="emiCardTitle text-center">
                    Installments
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">
                          {{ emi_Data.installments }}
                        </h3>
                      </span>
                    </span>
                  </h4>
                </div>
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
                    border-left: 1px solid #DFDFDF;
                    height: 50px;
                  "
                >
                  <h4 *ngIf="emi_Data.type != 'Daily Amount Based'" class="emiCardTitle text-center">
                    Total Weight
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">
                          {{ emi_Data.total_weight}}
                        </h3>
                      </span>
                    </span>
                  </h4>
                  <h4 *ngIf="emi_Data.type =='Daily Amount Based'" class="emiCardTitle text-center">
                    Due Installments
                    <span>
                      <span>
                        <h3 class="mt-2 emiCardValue">
                          {{ emi_Data.due_installments}}
                        </h3>
                      </span>
                    </span>
                  
                  </h4>

                </div>
                <div
                  class="col-md-1 mt-3"
                  style="
                    width: 12.499999995%;
                    border-left: 1px solid #DFDFDF;
                    height: 50px;
                  "
                >
                  <h4 class="emiCardTitle text-center">
                    Plan Status
                    <span>
                      <span>
                        <h3 [ngStyle]="{ color: getColor(emi_Data.status) }" class="mt-2 emiCardValue">
                          {{
                            emi_Data.status == "IP" ? "Active" :
                            emi_Data.status == "CP" ? "Completed" :
                            emi_Data.status == "CL" ? "Cancelled" :
                            emi_Data.status == "CD" ? "Closed" :
                            emi_Data.status == "PS" ? "Paused" :
                            emi_Data.status == "IG" ? "IN Grace":
                            "Deleted"
                          }}
                        </h3>
                      </span>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row tablePadding mt-2">
            <div class="col-md-12 text-center">
              <div class="mat-elevation table-responsive">
                <table
                  mat-table
                  [dataSource]="plan_dataSource"
                  matSort
                  class="my-table"
                  style="table-layout: auto; width: 100%"
                >
                  <!-- Position Column -->
                  <ng-container matColumnDef="plan_id" class="">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Plan Id
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.code }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="plan_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Plan name
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.plan_name }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="installments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Installment
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.installments }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="payment_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Installment Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.plan_date }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.amount }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="rate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Rate/Gm
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.rate }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="weight">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Weight
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.weight }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="payment_mode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Payment mode
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.payment_mode }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="payment_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Payment Id
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.payment_id }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="settlement_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Transfer Id
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.settlement_id }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="settlement_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Settlement Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.settlement_date }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="kebab">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element">
                      <span [ngStyle]=""></span>
                      <button
                        *ngIf="element.plan_id && emi_Data.status!=='CL'"
                        class="newbi"
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
  
                      >
                        <span
                          id="boot-icon"
                          class="bi bi-three-dots-vertical mt-1"
                          style="
                            font-size: 25px;
                            -webkit-text-stroke-color: rgb(0, 0, 0);
                          "
                        ></span>
                      </button>
                      <mat-menu #menu="matMenu" class="matmenu">
                        <!--  
                    [queryParams]="{planN:element.code,phone:element.phone,amount:element.cost} -->
                        <a
                          [routerLink]="
                            actionsCheck(21)
                              ? ['/dashboard/edit_installment']
                              : [null]
                          "
                          style="text-decoration: none"
                          [queryParams]="
                            actionsCheck(21)
                              ? {
                                  sCode: element.code,
                                  orderID: element.order_id,
                                  click: true,
                                  online: element.is_online,
                                  is_amount: emi_Data.type == 'Amount Based' || emi_Data.type == 'Daily Amount Based' ? true : false                                }
                              : null
                          "
                        >
                          <button
                            (click)="modal.dismiss('Cross click')"
                            mat-menu-item
                          >
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                          </button>
                        </a>
                        <button
                        (click)="printInstallment(element)"
                        mat-menu-item
                      >
                        <mat-icon>print</mat-icon>
                        <span>Print Installment</span>
                      </button>

                        <button
                          (click)="
                            actionsCheck(22)
                              ? deleteEmi(
                                  deleteInstallment,
                                  element.id,
                                  element.payment_mode,
                                  onlinedeleteInstallment
                                )
                              : null
                          "
                          mat-menu-item
                        >
                          <mat-icon>delete</mat-icon>
                          <span> Delete </span>
                        </button>


                        <!-- <button
                          (click)="printChit(element)"
                        mat-menu-item
                      >
                      <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 19.8063L6.5 11.5C6.5 10.5572 6.5 10.0858 6.79289 9.79289C7.08579 9.5 7.55719 9.5 8.5 9.5L15.5 9.5C16.4428 9.5 16.9142 9.5 17.2071 9.79289C17.5 10.0858 17.5 10.5572 17.5 11.5L17.5 19.8063C17.5 20.1228 17.5 20.2811 17.3962 20.356C17.2924 20.4308 17.1422 20.3807 16.8419 20.2806L14.6738 19.5579C14.5878 19.5293 14.5448 19.5149 14.5005 19.5162C14.4561 19.5175 14.4141 19.5344 14.3299 19.568L12.1857 20.4257C12.094 20.4624 12.0481 20.4807 12 20.4807C11.9519 20.4807 11.906 20.4624 11.8143 20.4257L9.67005 19.568C9.58592 19.5344 9.54385 19.5175 9.49952 19.5162C9.45519 19.5149 9.41221 19.5293 9.32625 19.5579L7.15811 20.2806C6.8578 20.3807 6.70764 20.4308 6.60382 20.356C6.5 20.2811 6.5 20.1228 6.5 19.8063Z" fill="#2A4157" fill-opacity="0.24" stroke="#222222"/>
                        <rect x="6.5" y="2.5" width="11" height="4" rx="1" fill="#2A4157" fill-opacity="0.24"/>
                        <path d="M18 13.5H18.5C19.4428 13.5 19.9142 13.5 20.2071 13.2071C20.5 12.9142 20.5 12.4428 20.5 11.5V10.5C20.5 8.61438 20.5 7.67157 19.9142 7.08579C19.3284 6.5 18.3856 6.5 16.5 6.5H7.5C5.61438 6.5 4.67157 6.5 4.08579 7.08579C3.5 7.67157 3.5 8.61438 3.5 10.5V12.5C3.5 12.9714 3.5 13.2071 3.64645 13.3536C3.79289 13.5 4.0286 13.5 4.5 13.5H6" stroke="#222222"/>
                        <path d="M9.5 13.5L13.5 13.5" stroke="#222222" stroke-linecap="round"/>
                        <path d="M9.5 16.5L14.5 16.5" stroke="#222222" stroke-linecap="round"/>
                        <path d="M17.5 6.5V6.1C17.5 4.40294 17.5 3.55442 16.9728 3.02721C16.4456 2.5 15.5971 2.5 13.9 2.5H10.1C8.40294 2.5 7.55442 2.5 7.02721 3.02721C6.5 3.55442 6.5 4.40294 6.5 6.1V6.5" stroke="#222222"/>
                        </svg>
                                              <span class="ms-3">Print </span>
                      </button> -->
                      </mat-menu>
                    </td>
                  </ng-container>
  
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsEmis"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsEmis"
                  ></tr>
                </table>
              </div>
            </div>
            <div *ngIf="current_plan.note">
              <div class="comments emi-modal-title">Note</div>
              <input
                class="comment_input"
                [disabled]="true"
                [(ngModel)]="current_plan.note"
              />
            </div>
            <div *ngIf="showCancel" class="cancel_data">
            <div
              class="mt-4"
              style="display: flex; justify-content: space-between"
            >
              <div class="emi-modal-title">Cancellation Details</div>
              <div
                class="mb-1 me-2"
                style="display: flex; justify-content: space-between"
              >
                <div  (click)=" actionsCheck(38) ? redirectCancelInstallment() :null"
                style="cursor:pointer"
                >
                  <span>
                    <img
                      src="assets/images/blueedit.svg"
                      width="20"
                      height="20"
                      style="margin-bottom: 5px"
                    />
                  </span>
                  <span class="editbuttonemi" > Edit Payment Mode </span>
                </div>
                <div class="ms-4"  style="cursor:pointer">
                  <span>
                    <img
                      src="assets/images/Print.svg"
                      width="20"
                      height="20"
                      style="margin-bottom: 4px"
                    />
                  </span>
                  <span (click)="actionsCheck(38) ? print() :null" class="editbuttonemi"> Re-print voucher </span>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div
                class="col-md-12 card"
                style="height: 101px; width: 98.5%; margin-left: 10px"
              >
                <div class="row mt-2">
                  <div
                    class="col-md-2 mt-3"
                    style=" height: 50px"
                  >
                    <h4 class="emiCardTitle text-center">
                      Cancellation Date
                      <span>
                        <span>
                          <h3 class="mt-2 emiCardValue">
                            {{ cancelled_plan.cancel_date | date : "dd/MM/yyyy" }}
                          </h3>
                        </span>
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-md-2 mt-3"
                    style="border-left: 1px solid #DFDFDF; height: 50px"
                  >
                    <h4 class="emiCardTitle text-center">
                      Collected Amount
                      <span>
                        <span>
                          <h3 class="mt-2 emiCardValue">
                            {{ cancelled_plan.amount_collected }}
                          </h3>
                        </span>
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-md-2 mt-3"
                    style="border-left: 1px solid #DFDFDF; height: 50px"
                  >
                    <h4 class="emiCardTitle text-center">
                      Deductions
                      <span>
                        <span>
                          <h3 class="mt-2 emiCardValue">
                            {{ cancelled_plan.deduction }}
                          </h3>
                        </span>
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-md-2 mt-3"
                    style="border-left: 1px solid #DFDFDF; height: 50px"
                  >
                    <h4 class="emiCardTitle text-center">
                      Amount Paid
                      <span>
                        <span>
                          <h3 class="mt-2 emiCardValue">
                            {{ cancelled_plan.amount_payable }}
                          </h3>
                        </span>
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-md-2 mt-3"
                    style="border-left: 1px solid #DFDFDF; height: 50px"
                  >
                    <h4 class="emiCardTitle text-center">
                      Payment Mode
                      <span>
                        <span>
                          <h3 class="mt-2 emiCardValue">
                            {{
                              cancelled_plan.payment_mode == "7"
                                ? "Cash"
                                : cancelled_plan.payment_mode == "3"
                                ? "NEFT/RTGS"
                                : cancelled_plan.payment_mode == "4"
                                ? "Online Payment"
                                : cancelled_plan.payment_mode == "0"
                                ? "UPI/IMPS"
                                : cancelled_plan.payment_mode == "6"
                                ? "Cheque"
                                : ""
                            }}
                          </h3>
                        </span>
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-md-2 mt-3"
                    style="border-left: 1px solid #DFDFDF; height: 50px"
                  >
                    <h4 class="emiCardTitle text-center">
                      Payment Date
                      <span>
                        <span>
                          <h3 class="mt-2 emiCardValue">
                            {{
                              cancelled_plan.payment_date | date : "dd/MM/yyyy"
                            }}
                          </h3>
                        </span>
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              <div *ngIf="cancelled_plan.comments">
                <div class="comments">Comments</div>
                <input
                  class="comment_input"
                  [disabled]="true"
                  [(ngModel)]="cancelled_plan.comments"
                />
              </div>
  
  
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #deleteInstallment let-modal>
    <div class="myModal">
      <div class="modal-content p-4" style="width: 100%; border-radius: 30px">
        <div class="modal-header" style="border: 0; height: 0">
          <h1 class="modal-title fbold"></h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img
              src="assets/images/errorsign.svg"
              class="img-responsive img-fluid"
            />
  
            <h1 class="fbold">Warning!</h1>
  
            <p class="greyColor">
              This Action cannot be undone are you sure you want to delete this
              details ?
            </p>
  
            <button
              (click)="deleteEmiInstallment(emi_id); modal.dismiss('Cross click')"
              type="button"
              class="btn btn-primary mt-3 addbutton1"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #onlinedeleteInstallment let-modal>
    <div class="myModal">
      <div class="modal-content p-4" style="width: 100%; border-radius: 30px">
        <div class="modal-header" style="border: 0; height: 0">
          <h1 class="modal-title fbold"></h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img
              src="assets/images/errorsign.svg"
              class="img-responsive img-fluid"
            />
  
            <h1 class="fbold">Warning!</h1>
  
            <p class="greyColor">
              You cannot Delete this transaction as the payment has been collected
              online. Contact customer support for help.
            </p>
  
            <button
              (click)="modal.dismiss('Cross click')"
              type="button"
              class="btn btn-primary mt-3 addbutton1"
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #download_file let-modal>
    <div class="emailModal">
      <div class="modal-header p-3" style="border: 0">
        <h1
          class="modal-title"
          style="
            font-family: 'Rubik';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            margin-left: 125px;
            margin-top: 15px;
            font-weight: 500;
            color: #262626;
          "
        >
          Download Data
        </h1>
        <button
          type="button"
          style="outline: none"
          class="close btn"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3
          style="
            font-family: 'rubik';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
          "
        >
          Choose Date
        </h3>
        <div class="row">
          <div class="col-sm-3">
            <mat-label class="model_labels">From</mat-label>
  
            <mat-form-field
              [style.width.%]="220"
              class="ms-1"
              appearance="outline"
              style="padding: 0; margin: 0"
            >
              <input
              (click)="picker.open()"
                matInput
                [matDatepicker]="picker"
                (dateInput)="downloadfDate($event.value)"
                placeholder="dd/mm/yyyy"
              />
              <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
              <mat-datepicker-toggle
                matPrefix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-3"></div>
          <div class="col-sm-3">
            <mat-label class="model_labels">To</mat-label>
            <mat-form-field

              [style.width.%]="220"
              appearance="outline"
              style="padding: 0; margin: 0"
            >
              <input
                matInput
                (click)="picker1.open()"
                [matDatepicker]="picker1"
                (dateInput)="downloadtDate($event.value)"
                placeholder="dd/mm/yyyy"
              />
              <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
              <mat-datepicker-toggle
                matPrefix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-ms-3"></div>
        </div>
        <div class="row ms-1 me-1 form-group">
          <div class="row" style="height: 310px"></div>
          <button
            (click)="file_download()"
            mat-raised-button
            color="primary"
            class="mt-5 btn-lg mb-2 ms-4 shad"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  
  
  <ng-template #close_plan let-modal>
    <div class="myModal">
      <div class="modal-content complete">
        <div class="modal-header header-box" style="border: 0; height: 0">
          <h1 class="modal-title header-title">Close Plan</h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body body_model">
          <div>
            <label class="in_completion_title"> Closing Date* </label>
            <input
              type="date"
              class="form-control in_completion mt-3"
              [(ngModel)]="closing_date"
              [disabled]="disable_closed_plan_date">
          </div>
          <div class="mt-3">
            <label class="in_completion_title"> Invoice No </label>
            <input
              id="invoice_no_input"
              placeholder="Enter Invoice no"
              class="form-control in_completion mt-3"
              [(ngModel)]="invoice"

            />
          </div>
          <div class="btn_completion_div mt-4">
            <button
              class="btn_completion"
              (click)="closePlan()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #close_plan_otp let-modal>
    <div class="myModal">
      <div class="modal-content complete">
        <div class="modal-header header-box" style="border: 0; height: 0">
          <h1 class="modal-title header-title">Close Plan</h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body body_model">
          <div>
            <label class="in_completion_title"> Closing Date* </label>
            <input
              type="date"
              class="form-control in_completion mt-3"
              [(ngModel)]="closing_date"
              [disabled]="disable_closed_plan_date">
          </div>
          <div class="mt-3">
            <label class="in_completion_title"> Invoice No </label>
            <input
              id="invoice_no_input"
              placeholder="Enter Invoice no"
              class="form-control in_completion mt-3"
              [(ngModel)]="invoice"

            />
          </div>
         

          <div class="mt-3">

            <label class="in_completion_title"> Mobile No </label>
            <div class="input-group" style="position: relative;">
    
              <input
                [(ngModel)]="closed_plan_phone"
                [disabled]="true"
                type="text"
                class="form-control in_completion"
                placeholder="Enter Invoice no"
              />
             <div class="resend_button" *ngIf="otp_sent"> 
              Resend in <span class="timer">{{counter}}</span> seconds
             </div>
    
              <button
              *ngIf="!otp_sent"
                mat-raised-button
                class="searchButton cursor verify_custom_button"
                style="
                  background: #4070f4;
                  color: #ffffff;
                  box-shadow: none;
                "
                (click)="otp_sent?'':sendOtp()"
              >
              {{otp_button_text}} 
              </button>
            </div>
            

            <!-- <input
              id="invoice_no_input"
              placeholder="Enter Invoice no"
              class="form-control in_completion mt-3"
              [(ngModel)]="closed_plan_phone"

            /> -->
          </div>
          <div class="mt-3" *ngIf="otp_sent">
            <label class="in_completion_title mb-3"> Enter Otp</label>
            <ng-otp-input
              #otpInput
              id="otp_input"
              (onInputChange)="onOtpChange($event)"
              [config]="{ length: 4, allowNumbersOnly: true }"
            ></ng-otp-input>
          </div>
          <div class="btn_completion_div mt-4">
            <button
              class="btn_completion"
              (click)="closePlan()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #add_notes let-modal>
    <div class="myModal">
      <div class="modal-content complete">
        <div class="modal-header header-box" style="border: 0; height: 0">
          <h1 class="modal-title header-title">Add Notes</h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body body_model">
          <div style="color: #636363;
          font-family: 'Rubik';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;">
            Note
          </div>
          <div>
            <textarea
              class="form-control in_completion mt-3 p-2"
              placeholder="Add notes Eg: Add stone on top"
              id="notes_input"
              (keydown.enter)="save_notes.focus()"
              [(ngModel)]="subscription_note.note"
            ></textarea>
          </div>
          <div class="btn_completion_div">
            <button
            #save_notes
              id="notes_save_button"
              class="btn_completion"
              (click)="saveNote();modal.dismiss('Cross click')"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <app-printer   #printComponent></app-printer>
