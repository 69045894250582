import { Location, formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopUpManager } from 'src/app/manager/Popup';
import { ClosePlan, EMI_DATA, Emi, SubscriptionNote } from 'src/app/models/ledger';
import { ResponseMessage } from 'src/app/models/message';
import { LedgerDataSource } from './customer_ledger_dataSource';
import { LedgerServiceService } from './ledger-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { HostListener } from '@angular/core';
import {
  NgbDropdownConfig,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

import { NewCustomerService } from 'src/app/new-customer/new-customer.service';

import { timer, Subscription } from 'rxjs';
import { elementAt, take } from 'rxjs/operators';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { PlanName } from 'src/app/models/planname';
import { HttpErrorResponse } from '@angular/common/http';

import { DateAdapter } from '@angular/material/core';
import { AnalyticService } from 'src/app/services/analytics.service';
import { TitleService } from 'src/app/services/title.service';
import { ActionService } from 'src/app/services/actions.service';
import { PageAccessService } from 'src/app/services/pageaccess.service';
import { CancelPlanService } from 'src/app/cancel-plan/cancel-plan.service';
import { CancelPlan } from 'src/app/models/cancelplan';
import { CancelPlanComponent } from 'src/app/cancel-plan/cancel-plan.component';
import { LoginService } from 'src/app/login/login.service';
import { PrinterComponent } from 'src/app/printer/printer.component';
import { InstallmentPrint } from 'src/app/models/installments';


@Component({
  selector: 'app-customer-ledger',
  templateUrl: './customer-ledger.component.html',
  styleUrls: ['./customer-ledger.component.css'],
  providers: [NgbDropdownConfig],
})
export class CustomerLedgerComponent implements OnInit {
  @ViewChild('printComponent') printComponent!: PrinterComponent;
  disable_closed_plan_date:boolean=false
  @ViewChild(CancelPlanComponent, {static: false}) cancelComponent!: CancelPlanComponent;
  countDown!: Subscription;
  counter = 60; //otp timer (in seconds)
  tick = 1000;
  ShowResend = true;

  current_plan!:any;

  printContent!: InstallmentPrint;
  emi_id: any;
  phonenumber = '';

  otp_button_text='Verify'

  closed_plan_phone!:string;
  closed_plan_otp!:string;

  otp_sent = false; 
  existingUser = false;
  existingUserid: any;
  search_field_disable!: boolean;
  idInstallment = '';
  disableFields = false;
  disableOP = false;
  otp_value: string = '';
  verifyIcon = false;
  data_exist=false;

  download_from_date = '';
  download_to_date = '';

  run = true; //to make timer work properly

  run1 = true;
  close_plan:ClosePlan = new ClosePlan('',formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),'','','');

  subscription_note:SubscriptionNote= new SubscriptionNote(0,"");


  selected_plan!:string;
  invoice!:string;
  closing_date:string=this.close_plan.closing_date;

  displayedColumns: string[] = [
    'plan_id',
    'customer_name',
    'phone',
    'plan_type',
    'cost',
    'duration',
    'start_date',
    'total_or_weight',
    'status',
    'installments',
    'kebab',
  ];

  cancelled_plan!:CancelPlan;

  table = true;
  createuser = false;
  showplanamount = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource!: LedgerDataSource;
  responseMessage: any = ResponseMessage;
  popUpManager!: PopUpManager;
  status = '';
  search = '';
  email = '';
  ordering: string = '-id';
  otpshow = true;
  allowed!: any;
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  check12:boolean=false;
  showCancel=false;



  constructor(
    private modalService: NgbModal,
    private ledgerService: LedgerServiceService,
    private _location: Location,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private newcustomerservice: LoginService,
    private config: NgbDropdownConfig,
    private dateAdapter: DateAdapter<Date>,
    private analysis: AnalyticService,
    private titleService: TitleService,
    private elem: ElementRef,
    private authservice : PageAccessService,
    private cancelService:CancelPlanService,


    private actionService: ActionService
  ) {
    config.placement = 'top-start';
    config.autoClose = false;
    this.dateAdapter.setLocale('en-IN');


  }

  formatForPrint(element:any) {
    const copy_emi: any = {}; // Initialize copy_emi as an empty object

    copy_emi.rc = element.is_online ? "--" : "PY"+element.payment_id;
    copy_emi.ins = parseInt(element.installments, 10).toString(); // Remove leading zeros
    copy_emi.date = element.plan_date;
    copy_emi.code = element.code;
    copy_emi.amount = parseFloat(element.amount).toFixed(2).replace(/\.00$/, '');
    copy_emi.wt = parseFloat(element.weight.toString()).toFixed(3);
    copy_emi.rt = parseFloat(element.rate.toString()).toFixed(2);
    copy_emi.tw = element.accumulated_weight ?   parseFloat(element.accumulated_weight).toFixed(3)  : "0.000"

    return `rc:${copy_emi.rc} ins:${copy_emi.ins}&date:${copy_emi.date}&${copy_emi.code}&rs:${copy_emi.amount}/Wt:${copy_emi.wt}&rt:${copy_emi.rt}/Tw:${copy_emi.tw}`;

  }

  printCustomerDetail()
  {
      const encodedData = encodeURIComponent('SGB8339 &Mat:Jul 2024 &Kanyaka sree j &3/7 bhobshanam &ayavaram');
    const customProtocolUrl = `print://${encodedData}`

    try {
     // Trigger the custom protocol by navigating to the URL

     window.location.href = customProtocolUrl;
     console.log("Attempted to navigate to:", customProtocolUrl);
 } catch (error) {
     console.error("Failed to trigger custom protocol:", error);
 }  }
  printInstallment(element:Emi)
  {
    const encodedData = encodeURIComponent(this.formatForPrint(element));
    const customProtocolUrl = `print://${encodedData}`
     try {
      // Trigger the custom protocol by navigating to the URL

      window.location.href = customProtocolUrl;
      console.log("Attempted to navigate to:", customProtocolUrl);
  } catch (error) {
      console.error("Failed to trigger custom protocol:", error);
  }
  }

  getInstallmentData(id:number)
  {
    this.ledgerService.getInstallmentData(id).subscribe((data:InstallmentPrint)=>
    {
      this.printContent = data;
      // console.log(this.printContent)
      // this.printComponent.setContent(data);
      // this.printComponent.toggleVisibility();

    })
  }
  saveNote(){
      this.ledgerService.saveNote(this.subscription_note).subscribe((data:any)=>
      {
        this.toastr.success('', "Note Added Successfully", {
          positionClass: 'toast-bottom-right',
        });
        this.current_plan.note= this.subscription_note.note;
      })
  }
  getNote(subs_id:number)
  {
    this.ledgerService.getNote(subs_id).subscribe((data:any)=>
    { 
        this.subscription_note.id = data.data.id;
        this.subscription_note.note= data.data.note;
      
    })
  }
  printChit(installment:any) {
    // this.check12=true;
    // this.printComponent.ngOnInit();

    console.log(installment)
    this.getInstallmentData(installment.id)
  }

  getClosedPlanData(plan:any,content:any)
  {
    this.ledgerService.getClosePlan(plan.id).subscribe((data:any)=>
    {
      console.log(data);
      this.closing_date=data.closed_plan.closing_date ? data.closed_plan.closing_date : formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
      this.invoice=data.closed_plan.invoice_no;
      this.closed_plan_phone=data.closed_plan.owner_number;
      this.openClosePlan(content,plan);
      if(plan.status=='Billed')
      {
        this.disable_closed_plan_date = true;
      }

    })
  }
 

  openClosePlan(content: any,element:any) {
    this.selected_plan=element.id;
    this.modalService.open(content, { centered: true });
    setTimeout(() => {
        (<HTMLInputElement>document.getElementById('invoice_no_input')).focus()
    }, 100);
  }

  searchOption() {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)/;
    if (regex.test(this.search)) {
      this.search = this.search.replace(/\s+/g, '');
    }
    
    this.resetAndDisplayTable();
  }
  fetchStatus(value: any) {
    console.log(value)
    this.status = value;

    this.resetAndDisplayTable();
  }

  back() {
    this._location.back();
  }
  get_plandataSource!: Array<PlanName>;
  plan_dataSource!: Array<Emi>;

  fdate: string = '';
  tdate: string = '';
  emi_Data!: EMI_DATA;

  displayedColumnsEmis: string[] = [
    // 'plan_id',
    // 'plan_name',
    'installments',
    'payment_date',
    'amount',
    'rate',
    'weight',
    'payment_mode',
    'payment_id',
    'settlement_id',
    'settlement_date',
    'kebab',
  ];


  memoizeTernary(condition: boolean, trueResult: any, falseResult: any) {
    return condition ? trueResult : falseResult;
  }

  openNotesModel(row:any,content: any) {
    this.subscription_note.note=row.note;
    this.subscription_note.id=row.id;
    this.modalService.open(content, {
      centered: true,
      size: 'md',
    });

  }
  ngOnInit(): void {



    this.allowed = this.actionService.getActions();
    this.actionAllowed();
    this.titleService.setTitle('Customer Ledgers');
    this.dataSource = new LedgerDataSource(this.ledgerService);
    this.dataSource.loadData(0, this.search, this.ordering, this.status);
    this.popUpManager = new PopUpManager(this.snackBar);
    this.analysis.logEvent('Customer Ledger Page Loaded');


  }



  actionAllowed() {
    if (this.actionsCheck(23)) {
      this.search_field_disable = false;
    } else {
      this.search_field_disable = true;
    }
  }

  // for closing the plan
  closePlan()
  {
    this.close_plan.id=this.selected_plan;
    this.close_plan.closing_date=this.closing_date;
    this.close_plan.invoice=this.invoice;
    this.close_plan.phone=this.closed_plan_phone;
    this.close_plan.otp=this.closed_plan_otp;

    this.ledgerService.closePlan(this.close_plan).subscribe((data:any)=>
    {
      console.log(data)
      this.toastr.success('', data.message, {
        positionClass: 'toast-bottom-right',
      });
      this.modalService.dismissAll()
      this.resetAndDisplayTable()
    },
    (error: any) => {
      console.log(error.error)
      this.toastr.info(
        '',
        error.error.message,
        { positionClass: 'toast-bottom-right' }
      );
      this.otp_sent=false;
    })
  }

  clearInput() {
    this.analysis.logEvent('User Clear Input Search field');
    this.search = '';
  }
  tests(element:any){console.log(element.plan_type)}
  getColor(status: any) {
    if (status == 'In Progress' || status == "IP") {
      return 'blue';
    } else if (status == 'Completed' ||  status == "CP")  {
      return 'green';
    }

    else if (status=='Billed')
    {
      return 'orange'
    }
    else if (status=='Paused' || status=='PS'){
      return 'purple'
    }
    else {
      return 'red';
    }

  }

  downloadfDate(event: any) {
    this.fdate = moment(event).format('DD-MM-YYYY');
    this.download_from_date = moment(event).format('YYYY-MM-DD');
  }
  downloadtDate(event: any) {
    this.tdate = moment(event).format('DD-MM-YYYY');
    this.download_to_date = moment(event).format('YYYY-MM-DD');

  }

  ngAfterViewInit(): void {
    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
      if(count != 0){
        this.data_exist=true;
      }
    });
    this.paginator.page.subscribe(() => {
      this.popUpManager.openSnackBar('Loading', 'cancel');
      this.analysis.logEvent('Customer Ledger DataSource / Table Loaded');
      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.search,
        this.ordering,
        this.status
      );
    });
    // let elements = this.elem.nativeElement.querySelectorAll('.cursor');

    // if (elements && this.allowed!="admin") {
    //   elements.forEach(
    //     (ele: { style: any; id(id: any): unknown; nativeElement: any }) => {


    //       if (this.allowed.includes(ele.id) ) {
    //         ele.style.cursor='pointer';
    //       } else {

    //         ele.style.cursor = 'not-allowed';
    //       }
    //     }
    //   );
    // }
    // let router_cursor = this.elem.nativeElement.querySelectorAll('.router_cursor');
    // if (router_cursor && this.allowed!="admin") {
    //   router_cursor.forEach(
    //     (ele: { style: any; id(id: any): unknown; nativeElement: any }) => {


    //       if (this.authservice.check2(ele.id) ) {

    //       } else {

    //         ele.style.cursor = 'not-allowed';
    //       }
    //     }
    //   );
    // }

  }

  sortbyId() {
    if (this.ordering == 'id') {
      this.ordering = '-id';
      this.resetAndDisplayTable();
    } else {
      this.ordering = 'id';
      this.resetAndDisplayTable();
    }
  }

  resetAndDisplayTable() {
    this.popUpManager.openSnackBar('Loading', 'cancel');

    this.paginator.pageIndex = 0;
    this.dataSource.loadData(0, this.search, this.ordering, this.status);
  }

  getEmis(element: any, content: any,code:any) {
    this.current_plan=element;
    console.log(this.current_plan)
    this.popUpManager.openSnackBar('Loading', 'cancel');
    this.showCancel=false;
    this.ledgerService.getEmiData(element.id).subscribe(
      (data: any) => {
        console.log(data);
        this.analysis.logEvent('Customer Ledger EMI Model Launched');
        var test = [];
        var arr = new Array(data.emi.length);
        var temp = data.emi[0].installments;

        this.emi_Data = data.subscription;
        console.log(data)
        if (this.emi_Data.status=="CL")
        {
          this.cancelService.getCancelledData(code).subscribe((data:any)=>
          {

            this.cancelled_plan=data

            this.showCancel=true;
          })

        }

        for (var i = 1; i < data.emi.length; i++) {
        
          if (data.emi[i].installments == temp) {
          console.log(data.emi[i],temp);
            arr[i] = 1;
          } else {
            temp = data.emi[i].installments;
            arr[i] = 0;
          }
        }

        for (var j = 0; j < data.emi.length; j++) {
          if (arr[j] == 1) {
            test.push({
              plan_id: '',
              code: '',
              installments: '',
              plan_name: '',
              plan_date: '',
              amount: data.emi[j].amount,
              is_online: data.emi[j].is_online,
              rate: data.emi[j].rate,
              weight: data.emi[j].weight,
              payment_mode: data.emi[j].payment_mode,
              payment_id: data.emi[j].payment_id,
              settlement_date: data.emi[j].settlement_date,
              settlement_id: data.emi[j].settlement_id,
            });
          } else {
            test.push(data.emi[j]);
          }
        }

        this.plan_dataSource = test;

        this.modalService.open(content, { centered: true, size: <any>'xl' });


      },
      (error: any) => {
        this.analysis.logEvent("Customer Ledger EMI Model Does'nt Load");
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  // Generate the CSV data
  generateCsvData(data: any[], headers: string[]): string {
    let csvData = '';

    // Add headers to the CSV data
    csvData += headers.join(',') + '\n';

    // Iterate over the data and add it to the CSV string
    data.forEach((row: any) => {
        headers.forEach((header: string, index: number) => {
            // Ensure that the value is properly escaped
            let value = row[header];
            if (typeof value === 'string') {
                value = `"${value.replace(/"/g, '""')}"`;
            }
            csvData += (index > 0 ? ',' : '') + value;
        });
        csvData += '\n';
    });

    return csvData;
}

// Download the CSV data
downloadCsvData(data:any) {
  // Get the three months of data
 
  const headers = Object.keys(data[0]);

  // Generate the CSV data
  const csvData = this.generateCsvData(data,headers);

  // Create a Blob object from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a URL object from the Blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element and set its href attribute to the URL object
  const anchorElement = document.createElement('a');
  anchorElement.href = url;
  anchorElement.download = 'ledgers.csv';

  // Click the anchor element to start the download
  anchorElement.click();
}



 formatDate(dateString:any) {
  // Split the string time into an array of components
  const components = dateString.split('-');

  // Reverse the order of the components in the array
  components.reverse();

  // Join the components in the array back into a string, using the '-' character as the delimiter
  const formattedDateString = components.join('-');

  return formattedDateString;
}


  file_download() {

    var fd = new FormData();

    if (this.fdate == '') {
      this.toastr.info('', 'Please do select from date');
      return;
    } else if (this.tdate == '') {
      this.toastr.info('', 'Please do select to date');
      return;
    }

    const fromDate = moment(this.download_from_date);
    const toDate = moment(this.download_to_date);
    const differenceInDays = toDate.diff(fromDate, 'days');

    if(differenceInDays>90){
      this.toastr.info(
        '',
        'You can download only 90 days of data.please do select from and to date within 90 days.',
        { positionClass: 'toast-bottom-right' }
      );
      return;
    }


    this.modalService.dismissAll();
    fd.append('from_date', this.fdate);
    fd.append('to_date', this.tdate);
    fd.append('email', this.email);
    this.toastr.info(
      '',
      'Downloading...',
      { positionClass: 'toast-bottom-right' }
    );

    this.ledgerService.downloadLedger(fd).subscribe(
      (data:any) => {
        this.responseMessage = data;
        // var paraseData = JSON.parse(data['data']);
        // console.log(paraseData);
        var final_data=data['data'];
        if(final_data.length==0){
          this.toastr.info(
            '',
            'No data found in the selected duration.',
            { positionClass: 'toast-bottom-right' }
          );
        }else{
          this.downloadCsvData(final_data);
          this.analysis.logEvent(
            'Customer Ledger Datasource Download Request Sent'
          );
          this.responseMessage = data;
          this.toastr.success('', this.responseMessage.message, {
            positionClass: 'toast-bottom-right',
          });
        }
       
      },
      (error: any) => {
        this.analysis.logEvent(
          'Customer Ledger Download Datasource Request Failed'
        );
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  download(content: any) {
    this.modalService.open(content);
  }

  showContent(content: any) {
    this.modalService.open(content, { centered: true });
  }
  deleteEmi(content: any, id: any, mode: any, content_: any) {
    this.emi_id = id;
    if (mode == 'Online (C)') {
      this.modalService.open(content_, { centered: true });
      return;
    }

    this.modalService.open(content, { centered: true });
  }

  gotoInstallment() {
    this.analysis.logEvent('Customer Navigate to Add Installment');
    this.router.navigate([
      '/dashboard/add_installments/',
      { sub_code: this.idInstallment },
    ]);
  }

  deleteEmiInstallment(id: any) {
    this.ledgerService.deleteEmiRow(id).subscribe(
      (data) => {
        this.analysis.logEvent('Customer Ledger Delete Emi Done Successfully');
        this.responseMessage = data;

        this.toastr.success('', this.responseMessage.message, {
          positionClass: 'toast-bottom-right',
        });
        localStorage.setItem('subs_ID', this.idInstallment);
        window.setTimeout(() => {
          location.reload();
        }, 10);
      },
      (error: any) => {
        this.analysis.logEvent('Customer Ledger Delete Emi Request Failed');
        this.toastr.info('', 'Something Went Wrong.', {
          positionClass: 'toast-bottom-right',
        });
        window.setTimeout(() => {
          location.reload();
        }, 10);
      }
    );
  }

  handleKebab(plan_status: any) {
    if ( plan_status=='Cancelled' || plan_status=='Closed') {
      return false;
    } else {
      return true;
    }
  }

  actionsCheck(value: any) 
  {
    
    if (this.allowed == 'admin') {
      return true;
    }
    if (this.allowed.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  redirectCancelInstallment()
  {
    this.router.navigate(['/dashboard/cancel_plan',{planN:this.emi_Data.code}]);

    this.modalService.dismissAll();
  }

  print()
  {
    this.router.navigate(['/dashboard/cancel_plan',{print_planN:this.emi_Data.code}]);
    this.modalService.dismissAll();
  }

  timerFun() {
    this.otp_button_text='Resend';
    if (this.otp_sent == true) {
      this.counter = 60;
      this.countDown = timer(0, this.tick)
        .pipe(take(this.counter))
        .subscribe(() => {
          --this.counter;

          if (this.counter == 0) {
            this.countDown.unsubscribe();
            this.otp_sent=false;
          }
        });
    }
  }
  sendOtp()
  {
    this.ledgerService.sendOtpForClosePlan(this.closed_plan_phone).subscribe(
      (data) => {
        this.responseMessage = data;
        
        this.toastr.success('', this.responseMessage.message, {
          positionClass: 'toast-bottom-right',
        });
        this.otp_sent = true;
        this.timerFun()
      },
    );
  }
  onOtpChange(value: any) {
    if(!isNaN(Number(value)))
    {
     if (value.length == 4) {
       this.closed_plan_otp = value;
     }}
   }
   routeToPendingInstallment(){
    this.router.navigate(["/dashboard/pending_installment/"]);
   }

  @HostListener('document:keydown', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'i') {
      this.analysis.logEvent('User Navigate to Add Installments');
      this.router.navigate(['/dashboard/add_installments/']);
    }
  }
}